import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet-async";
//@Mui
import {
  CssBaseline,
  Grid,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import MemoryIcon from "@mui/icons-material/Memory";
import { useParams } from "react-router-dom"; // For extracting the id from URL
// Import custom components
import UrlListManager from "./urlsList/UrlListManager"; // Separate component to showcase displayed URLs
import DeviceContext from "./store/device-context";
import { accessTokenForApi } from "../../../authentication/authConfig";
import DeviceForm from "./DeviceForm";
import RestartDeviceButton from "./RestartDeviceButton";
import AddNewUrlButton from "./AddNewUrlButton";

const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

// Function to capitalize the first letter of each word, but only if no hyphens
const capitalizeBoardName = (name) => {
  if (name.includes("-")) {
    return name; // If name contains hyphen, return as it is
  } else {
    return name
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
};

//------------------------ Device Overview Page Component ---------------------------
const DeviceOverviewPage = () => {
  // Get id from URL
  const { id } = useParams(); // Get id from URL
  // Use the id parameter as needed
  const [editMode, setEditMode] = useState(false);
  const [initialFormData, setInitialFormData] = useState({
    deviceId: "",
    deviceName: "",
    ipAddress: "",
    lastUpdated: "",
    status: true,
    piUser: "",
    owner: "",
    team: "",
    officeName: "",
    officeLocation: "",
    teamid: "",
    deviceFloor: "",
    officeId: "",
    // Add other fields as needed
  });

  const [formData, setFormData] = useState(initialFormData);
  const [isLoading, setIsLoading] = useState(false);
  const [urlAddOrUpdateStatus, setUrlAddOrUpdateStatus] = useState(false);

  //----------------------- Handlers -----------------------

  const handleUrlAddOrUpdateStatusChange = (status) => {
    setUrlAddOrUpdateStatus(status);
  };

  //-----------------------Backend API Handlers -----------------------

  // Fetch device details from the DB
  const fetchDeviceDetails = useCallback(async () => {
    setIsLoading(true);
    const authenticatedValue = await accessTokenForApi(); // Assuming this fetches the access token
    try {
      const response = await fetch(
        `${BASE_URL}/board/getBoard/${id}`, // Use dynamic id from URL
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authenticatedValue.accessToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Something went wrong");
      }

      const data = await response.json();
      const transformedData = data.resultSet.data[0];
      setInitialFormData({
        deviceId: transformedData.board_id,
        deviceName: transformedData.board_name,
        ipAddress: transformedData.ip_address,
        lastUpdated: transformedData.updated_at,
        status: transformedData.active,
        piUser: transformedData.pi_user,
        owner: transformedData.board_owner,
        team: transformedData.team_name,
        teamid: transformedData.team_id,
        officeLocation: transformedData.office_location,
        officeName: transformedData.office_name,
        officeId: transformedData.office_id,
        deviceFloor: transformedData.board_floor,
      });
      setFormData({
        deviceId: transformedData.board_id,
        deviceName: transformedData.board_name,
        ipAddress: transformedData.ip_address,
        lastUpdated: transformedData.updated_at,
        status: transformedData.active,
        piUser: transformedData.pi_user,
        owner: transformedData.board_owner,
        team: transformedData.team_name,
        teamid: transformedData.team_id,
        officeLocation: transformedData.office_location,
        officeName: transformedData.office_name,
        officeId: transformedData.office_id,
        deviceFloor: transformedData.board_floor,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error.message); // Set error message
    }
  }, [id]); // Add `id` as a dependency to refetch when the id changes

  //----------------------- useEffect Handlers -----------------------

  useEffect(() => {
    fetchDeviceDetails();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Helmet>
        <title> Device | Dashboards </title>
      </Helmet>
      <CssBaseline />
      {isLoading ? (
        <Box sx={{ flexGrow: 1, textAlign: "center", paddingTop: 7 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2} sx={{ marginTop: 5 }}>
          {/* --------------- Title and Restart Buttons --------------- */}
          <Grid item xs={12}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              bgcolor="white"
              p={3}
              borderRadius={2}
              boxShadow={2}
            >
              {/* Left side: Memory Icon and Title */}
              <Box display="flex" alignItems="center">
                <MemoryIcon
                  sx={{ fontSize: 50, color: "#8A2BE2", marginRight: 2 }}
                />{" "}
                {/* Violet color Memory Icon */}
                <Box>
                  <Typography
                    variant="h5" // Adjusted to h5 for a larger size
                    sx={{
                      color: "#022F8E",
                    }}
                  >
                    {capitalizeBoardName(formData.deviceName)}
                  </Typography>

                  <Box display="flex" alignItems="center" gap={1}>
                    {/* Light indicator */}
                    <Box
                      sx={{
                        width: 12,
                        height: 12,
                        borderRadius: "50%",
                        backgroundColor: formData.status
                          ? "#66bb6a"
                          : "#f44336", // Green for online, red for offline
                        marginRight: 0.5, // Slight spacing between light and text
                      }}
                    />
                    {/* Status Text */}
                    <Typography
                      variant="body2"
                      sx={{
                        color: formData.status ? "#66bb6a" : "#f44336", // Green for online, red for offline
                        fontWeight: "bold",
                        display: "inline-block",
                        verticalAlign: "middle", // Ensure text is vertically aligned
                        lineHeight: "normal", // Prevent any vertical misalignment
                      }}
                    >
                      {formData.status ? "Online" : "Offline"}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              {/* Right side: Buttons */}
              <Box display="flex" alignItems="center" gap={2}>
                <RestartDeviceButton
                  deviceName={formData.deviceName}
                  deviceId={formData.deviceId}
                />
                <AddNewUrlButton
                  deviceName={formData.deviceName}
                  deviceId={formData.deviceId}
                  isUpdateOperation={false}
                  onUrlAddOrUpdateStatusChange={
                    handleUrlAddOrUpdateStatusChange
                  }
                />
              </Box>
            </Box>
          </Grid>

          {/* --------------- Device Overview (Second Row) --------------- */}
          <Grid item xs={12}>
            <DeviceForm
              formData={formData}
              editMode={editMode}
              setEditMode={setEditMode}
              setFormData={setFormData}
              initialFormData={initialFormData}
              setInitialFormData={setInitialFormData}
            />
          </Grid>

          {/* --------------- URL Section (Third Row) --------------- */}
          <Grid item xs={12}>
            <DeviceContext.Provider
              value={{
                deviceId: formData.deviceId,
                deviceName: formData.deviceName,
              }}
            >
              <UrlListManager
                title="URLs List"
                urlAddStatus={urlAddOrUpdateStatus}
              />
            </DeviceContext.Provider>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default DeviceOverviewPage;
