import React, { Fragment, useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  Stack,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Avatar,
  Button,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { useMsal } from "@azure/msal-react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../../../assets/logo.svg";
import {
  loginRequest,
  accessTokenForProfilePic,
} from "../../../authentication/authConfig";

// Global constants
const pages = ["home", "offices", "teams", "pi_devices"];
const settings = ["Logout"];

const pageKeyToTextMap = {
  home: "Home",
  offices: "Offices",
  teams: "Teams",
  pi_devices: "Raspberry Pis",
  // other mappings...
};
const getMenuItemText = (pageKey) => {
  return pageKeyToTextMap[pageKey] || pageKey;
};

const MUIHeader = (props) => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const activeAccount = instance.getActiveAccount();
  const isAuthenticated = useIsAuthenticated();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [image, setImage] = useState(null);

  const handleLoginButtonClick = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (event) => {
    const action = event.currentTarget.getAttribute("data-action");

    if (action === "Logout") {
      instance.logoutRedirect();
      localStorage.clear();
      sessionStorage.clear();
    }

    setAnchorElUser(null);
  };

  const validateToken = async () => {
    if (!activeAccount) {
      navigate("/");
      return;
    }

    try {
      const accessTokenRequest = {
        scopes: ["api://" + process.env.REACT_APP_AD_CLIENT_ID + "/Api.Read"],
        account: activeAccount,
      };

      await instance.acquireTokenSilent(accessTokenRequest);
    } catch (error) {
      console.error("Token expired or invalid:", error);
      navigate("/");
      instance.logoutRedirect(); // Log the user out on token failure
    }
  };

  const GetProfilePicture = async () => {
    const authenticatedValue = await accessTokenForProfilePic();
    const headers = new Headers();
    const bearer = "Bearer " + authenticatedValue.accessToken;
    headers.append("Authorization", bearer);
    headers.append("Content-type", "image/jpeg");
    const options = {
      method: "GET",
      headers: headers,
    };

    await fetch(
      "https://graph.microsoft.com/v1.0/me/photo/$value",
      options
    ).then((response) => {
      response.blob().then((data) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => {
          const base64data = reader.result;
          setImage(base64data);
        };
      });
    });
  };

  useEffect(() => {
    if (isAuthenticated) {
      validateToken();
      GetProfilePicture();
    }
    //eslint-disable-next-line
  }, [isAuthenticated]);

  return (
    <Fragment>
      <Box>
        <AppBar
          position="fixed"
          elevation={0}
          sx={{ bgcolor: "#273036", zIndex: "1400" }}
        >
          <Toolbar disableGutters>
            <AuthenticatedTemplate>
              <Button
                sx={{ padding: 0 }}
                disableRipple
                component={NavLink}
                underline="none"
                to={"/dashboard/home"}
              >
                <img
                  alt="company logo"
                  src={Logo}
                  sx={{ marginLeft: 20, height: "3em", width: "6em" }}
                />
              </Button>
              <Typography
                variant="h6"
                noWrap
                component={NavLink}
                to={"/dashboard/home"}
                sx={{
                  mr: 2,
                  display: { xs: "none", md: "flex" },
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                Dashboard Insights
              </Typography>
              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <div>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    keepMounted
                    transformOrigin={{ vertical: "top", horizontal: "left" }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{ display: { xs: "block", md: "none" } }}
                  >
                    {pages.map((page) => (
                      <MenuItem key={page} onClick={handleCloseNavMenu}>
                        <Typography textAlign="center">{page}</Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              </Box>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
              <Button
                sx={{ padding: 0 }}
                disableRipple
                component={NavLink}
                to={"/"}
              >
                <img
                  alt="company logo"
                  src={Logo}
                  sx={{ marginLeft: 20, height: "3em", width: "6em" }}
                />
              </Button>
              <Typography
                variant="h6"
                noWrap
                component={NavLink}
                to={"/"}
                sx={{
                  mr: 2,
                  display: { xs: "none", md: "flex" },
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "inherit",
                  textDecoration: "none",
                }}
              >
                Dashboard Insights
              </Typography>
              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <Button
                  color="inherit"
                  variant="outlined"
                  onClick={handleLoginButtonClick}
                  component={Link}
                  to={"/dashboard"}
                >
                  Login
                </Button>
              </Box>
            </UnauthenticatedTemplate>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex", lg: "flex" },
                justifyContent: "flex-end",
                mr: 3,
              }}
            >
              <AuthenticatedTemplate>
                <Stack direction="row" spacing={2}>
                  {pages.map((page) => (
                    <NavLink
                      key={page}
                      style={({ isActive }) => ({
                        color: isActive ? "#FFFFFF" : "Grey",
                        textDecoration: "inherit",
                      })}
                      to={"/dashboard/" + page}
                    >
                      {getMenuItemText(page)}
                    </NavLink>
                  ))}
                </Stack>
              </AuthenticatedTemplate>

              <UnauthenticatedTemplate>
                <Button
                  color="inherit"
                  variant="outlined"
                  onClick={handleLoginButtonClick}
                >
                  Login
                </Button>
              </UnauthenticatedTemplate>
            </Box>

            <AuthenticatedTemplate>
              <Box sx={{ flexGrow: 0, paddingRight: 5 }}>
                <Tooltip
                  title={activeAccount ? activeAccount.username : "User"}
                >
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      alt={activeAccount ? activeAccount.name : "User"}
                      src={image}
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  keepMounted
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem
                      key={setting}
                      onClick={handleCloseUserMenu}
                      data-action={setting}
                    >
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </AuthenticatedTemplate>
          </Toolbar>
        </AppBar>
      </Box>
    </Fragment>
  );
};

export default MUIHeader;
