import { accessTokenForApi } from "../../authentication/authConfig";

const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

export const addRecentActivity = async (operation) => {
    try {
      const authenticatedValue = await accessTokenForApi();
     await fetch(BASE_URL + "/operations/addOperationsPerformed", {
        method: "POST",
        body: JSON.stringify(operation),
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer "+authenticatedValue.accessToken,
        },
      });
  
    } catch (error) {
      console.log(error.message);
    }
  };
