import React, { useState, useEffect, useCallback, useRef } from "react";
import { useMsal } from "@azure/msal-react";
import {
  Box,
  Button,
  Typography,
  Switch,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Edit } from "@mui/icons-material"; // Material UI icons
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

//Custom components
import { accessTokenForApi } from "../../../authentication/authConfig";
import InputTextField from "../../UI/FormInput/InputTextField";
import InputSelectList from "../../UI/FormInput/InputSelectList";
import Iconify from "../../iconify/Iconify";
import { addRecentActivity } from "../../activityTracker/activityUpdate";
import CommonAlert from "../../UI/alerts/CommonAlert";

const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

// Function to convert date to CET timezone
const convertToCET = (dateString) => {
  const date = new Date(dateString);

  // Format the date in CET (Central European Time) with timezone
  const options = {
    year: "numeric", // Full year (e.g., 2024)
    month: "long", // Full month name (e.g., December)
    day: "numeric", // Day of the month (e.g., 23)
    hour: "2-digit", // Hour (e.g., 19)
    minute: "2-digit", // Minute (e.g., 14)
    second: "2-digit", // Second (e.g., 58)
    timeZoneName: "short", // Abbreviated timezone (e.g., CET)
    hour12: false, // Use 24-hour clock
    timeZone: "CET", // Set to Central European Time
  };
  const cetDate = date.toLocaleString("en-GB", options); // "en-GB" gives European style (day/month/year)
  return cetDate;
};

//Device form component definition starts here
const DeviceForm = ({
  formData,
  editMode,
  setEditMode,
  setFormData,
  initialFormData,
  setInitialFormData,
}) => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const initialFormDataRef = useRef(formData);

  //State values
  const [formIsValid, setFormIsValid] = useState(false);
  //Board name state values
  const [boardNameError, setBoardNameError] = useState(false);
  const [boardNameErrorMsg, setBoardNameErrorMsg] = useState("");
  //Team state values
  const [teamsList, setTeamsList] = useState([]);
  const [teamError, setTeamError] = useState(false);
  const [fetchTeamsListError, setFetchTeamsListError] = useState(""); // Handle fetch error
  const [teamErrorMsg, setTeamErrorMsg] = useState(""); // Error message for team selection
  //piUser state values
  const [piUserError, setPiUserError] = useState(false);
  const [piUserErrorMsg, setPiUserErrorMsg] = useState(false);
  //Pi owner state values
  const [ownerNameError, setOwnerNameError] = useState(false);
  const [ownerNameErrorMsg, setOwnerNameErrorMsg] = useState(false);
  //office state values
  const [officeList, setOfficeList] = useState([]);
  const [fetchOfficeListError, setFetchOfficeListError] = useState(null);
  const [officeNameError, setOfficeNameError] = useState(false);
  const [officeNameErrorMsg, setOfficeNameErrorMsg] = useState("");
  //Device Floor state values
  const [floorListValues, setFloorListValues] = useState([]);
  const [boardFloorError, setBoardFloorError] = useState(false);
  const [boardFloorErrorMsg, setBoardFloorErrorMsg] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  //Update in DB state values
  const [updateDeviceError, setUpdateDeviceError] = useState(null);
  //Alert state values
  const [commonAlertTrigger, setCommonAlertTrigger] = useState(false);
  //Copy to clipboard state values
  //eslint-disable-next-line
  const [copySuccess, setCopySuccess] = useState("");

  //----------------- Backend API calls -------------------

  // Function to fetch the teams list
  const fetchTeamsList = useCallback(async () => {
    const authenticatedValue = await accessTokenForApi();
    try {
      const response = await fetch(BASE_URL + "/team/getAllTeams", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authenticatedValue.accessToken,
        },
      });
      if (!response.ok) {
        throw new Error("Something went wrong");
      }
      const data = await response.json();
      const transformedList = data.resultSet.map((listData) => {
        return {
          value: listData.team_id,
          text: listData.team_name,
        };
      });
      setTeamsList(transformedList);
    } catch (error) {
      setFetchTeamsListError(error.message);
      console.log(fetchTeamsListError);
    }
    // eslint-disable-next-line
  }, [formData.teamid]);

  //Fetch office details
  const fetchOfficeList = useCallback(async () => {
    setFetchOfficeListError(null);
    const authenticatedValue = await accessTokenForApi();
    try {
      const response = await fetch(BASE_URL + "/office/getAllOffice", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authenticatedValue.accessToken,
        },
      });
      if (!response.ok) {
        throw new Error("Something went wrong");
      }
      const data = await response.json();
      const transformedList = data.resultSet.data.map((listData) => {
        return {
          officeId: listData.office_id,
          officeName: listData.office_name,
          officeLocation: listData.office_location,
          floors: listData.office_floor, // Array of floor options
        };
      });
      // Sort by office name
      transformedList.sort((a, b) => a.officeName.localeCompare(b.officeName));
      setOfficeList(transformedList);
      // setOfficeNamesList(transformedList.map((item) => item.officeName)); // Populate office names
    } catch (error) {
      setFetchOfficeListError(error.message);
      console.error(fetchOfficeListError);
    }
    // eslint-disable-next-line
  }, []);

  //Fetch selected office floor list
  const fetchSelectedOfficeFloorList = useCallback(async (officeId) => {
    let officeFloors = [];
    if (officeId) {
      try {
        const authenticatedValue = await accessTokenForApi();
        const response = await fetch(
          BASE_URL + `/office/getOfficefloor/${officeId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + authenticatedValue.accessToken,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Something went wrong");
        }
        const data = await response.json();

        // Ensure the floors are extracted correctly from the API response
        const transformedList = data.resultSet.data.map((listData) => {
          return {
            officefloor: listData.office_floor,
          };
        });
        transformedList[0].officefloor.forEach((floor) => {
          officeFloors.push({
            text: floor,
            value: floor,
          });
        });
        setFloorListValues(officeFloors);
      } catch (error) {
        console.error("Error fetching floors:", error.message);
      }
    }
  }, []);

  // Function to update the device in the database
  const updateDeviceHandler = async (device) => {
    const operation = {
      operations_performed: "Updated",
      component_type: "Device",
      component_name: device.board_name,
      update_by: activeAccount.name,
    };
    setUpdateDeviceError(null);
    try {
      const authenticatedValue = await accessTokenForApi();
      const response = await fetch(BASE_URL + "/board/updateBoard", {
        method: "PUT",
        body: JSON.stringify(device),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authenticatedValue.accessToken,
        },
      });
      const data = await response.json();
      if (data.Status === "success") {
        console.log("Dashboard updated successfully");
        setInitialFormData({
          deviceId: device.board_id,
          deviceName: device.board_name,
          owner: device.board_owner,
          piUser: device.pi_user,
          deviceFloor: device.board_floor,
          officeId: device.office_id,
          teamid: device.team_id,
          status: device.active,
        });

        addRecentActivity(operation);
        setEditMode(false); // Exit edit mode
      }
    } catch (error) {
      setUpdateDeviceError(error.message || "Something went wrong!");
      console.log(updateDeviceError);
    }
  };

  //----------------- Handle functions for operations -------------------

  // Form validation logic
  const validateForm = () => {
    let isValid = true;

    // Validate required fields
    if (
      !formData.piUser ||
      !/^[a-zA-Z0-9. ]+$/.test(formData.piUser) ||
      !formData.piUser.toLowerCase().includes("mr.monitor")
    ) {
      setPiUserError(true);
      setPiUserErrorMsg(
        "Pi User must contain 'mr.monitor' and be alphanumeric."
      );
      isValid = false;
    } else {
      setPiUserError(false);
      setPiUserErrorMsg("");
    }

    if (!formData.owner || !/^[a-zA-Z @]+$/.test(formData.owner)) {
      setOwnerNameError(true);
      setOwnerNameErrorMsg("Only letters, spaces, and @ are allowed.");
      isValid = false;
    } else {
      setOwnerNameError(false);
      setOwnerNameErrorMsg("");
    }

    if (!formData.deviceName || !/^[a-zA-Z0-9- ]+$/.test(formData.deviceName)) {
      setBoardNameError(true);
      setBoardNameErrorMsg("Only letters and numbers are allowed.");
      isValid = false;
    } else {
      setBoardNameError(false);
      setBoardNameErrorMsg("");
    }

    if (!formData.teamid) {
      setTeamError(true);
      setTeamErrorMsg("Team cannot be empty.");
      isValid = false;
    } else {
      setTeamError(false);
      setTeamErrorMsg("");
    }

    if (!formData.officeId) {
      setOfficeNameError(true);
      setOfficeNameErrorMsg("Office cannot be empty.");
      isValid = false;
    } else {
      setOfficeNameError(false);
      setOfficeNameErrorMsg("");
    }

    if (!formData.deviceFloor) {
      setBoardFloorError(true);
      setBoardFloorErrorMsg("Floor cannot be empty.");
      isValid = false;
    } else {
      setBoardFloorError(false);
      setBoardFloorErrorMsg("");
    }

    // Check if any of the fields have changed by comparing with initial form data
    const formChanged =
      formData.deviceName !== initialFormDataRef.current.deviceName ||
      formData.piUser !== initialFormDataRef.current.piUser ||
      formData.owner !== initialFormDataRef.current.owner ||
      formData.teamid !== initialFormDataRef.current.teamid ||
      formData.officeId !== initialFormDataRef.current.officeId ||
      formData.deviceFloor !== initialFormDataRef.current.deviceFloor ||
      formData.status !== initialFormDataRef.current.status;

    // Set the form as valid if there are changes
    setFormIsValid(isValid && formChanged);
  };

  // Toggle edit mode
  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value, // This ensures the right field in formData is updated
    }));
    // Validate the form
    validateForm();
  };

  const handleStatusToggle = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      status: e.target.checked,
    }));
    // Validate the form
    validateForm();
  };

  // Handle team change
  const handleTeamChange = (e, field) => {
    const value = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    // Validate the form
    validateForm();
  };

  const handleOfficeDetailChange = (e, field) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: e.target.value,
    }));
    // If office name is changed, fetch the corresponding floor list
    if (field === "officeId") {
      formData.deviceFloor = ""; // Reset the board floor
      // Fetch the floors for the selected office
      fetchSelectedOfficeFloorList(e.target.value);
    }
    // Validate the form
    validateForm();
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    // Perform final validation
    if (!formIsValid) return;
    setIsUpdating(true); // Disable buttons while updating
    try {
      const board = {
        board_id: formData.deviceId,
        board_name: formData.deviceName,
        board_owner: formData.owner,
        pi_user: formData.piUser,
        board_floor: formData.deviceFloor,
        office_id: formData.officeId,
        team_id: formData.teamid,
        updated_by: activeAccount?.username,
        active: formData.status,
      };
      await updateDeviceHandler(board); // Update the dashboard
    } catch (error) {
      console.error("Update failed:", error);
    } finally {
      setIsUpdating(false); // Re-enable buttons
    }
  };

  const handleCancel = () => {
    // Alert the user
    setCommonAlertTrigger(true);
    // Reset formData to its original values
    setFormData(initialFormData); // Assume `initialFormData` stores the original unedited data
    // Clear any field-specific error states
    setBoardNameError(false);
    setPiUserError(false);
    setOwnerNameError(false);
    setOfficeNameError(false);
    setBoardFloorError(false);
    setTeamError(false);
    // Exit edit mode
    setEditMode(false);
  };

  // Handle Copy to Clipboard
  const handleCopy = (text, fieldName) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopySuccess(`${fieldName} copied!`);
        setTimeout(() => setCopySuccess(""), 2000); // Clear success message after 2 seconds
      })
      .catch(() => {
        setCopySuccess(`Failed to copy ${fieldName}.`);
        setTimeout(() => setCopySuccess(""), 2000);
      });
  };
  //----------------- UseEffect hooks -------------------

  useEffect(() => {
    // Fetch teams list, and office list
    fetchTeamsList();
    fetchOfficeList();
    if (formData.officeId) {
      formData.deviceFloor = ""; // Reset the board floor
      fetchSelectedOfficeFloorList(formData.officeId);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchOfficeList();
    if (formData.officeId) {
      formData.deviceFloor = ""; // Reset the board floor
      fetchSelectedOfficeFloorList(formData.officeId);
    }
    // eslint-disable-next-line
  }, [formData.officeId]);

  useEffect(() => {
    validateForm();
    //eslint-disable-next-line
  }, [formData]);

  //-------------- Return the JSX for the device form ----------------
  return (
    <Box
      bgcolor="white"
      p={3}
      borderRadius={2}
      boxShadow={2}
      position="relative"
    >
      {commonAlertTrigger && (
        <CommonAlert
          open={commonAlertTrigger}
          title="Cancel changes"
          message="All edited changes has been cancelled."
          buttonTitle="OK"
          onClick={() => setCommonAlertTrigger(false)}
        />
      )}
      {/* Header */}
      <Typography
        variant="h6"
        mb={2}
        sx={{
          fontFamily: "Roboto",
          fontWeight: 500,
          fontSize: { xs: "none", md: 16, lg: 16, xl: 20 },
          letterSpacing: "0rem",
          color: "#022F8E",
          textDecoration: "none",
        }}
      >
        Device Overview
      </Typography>
      <Divider sx={{ my: 2 }} />
      {/* Content: Two Columns */}
      <Box display="flex" justifyContent="space-between">
        {/* Left Column */}
        <Box flex={1} mr={4}>
          <Box display="flex" justifyContent="space-between" sx={{ mb: 1 }}>
            <Typography sx={{ fontWeight: "bold" }}>Board ID</Typography>
            <Typography
              variant="h6"
              sx={{
                color: "text.secondary",
                paddingTop: 1,
                paddingLeft: 2,
                fontSize: { md: 12, lg: 12, xl: 14 },
              }}
              noWrap
            >
              {formData.deviceId}
            </Typography>
          </Box>

          <Box display="flex" justifyContent="space-between" sx={{ mb: 1 }}>
            <Typography sx={{ fontWeight: "bold" }}>IP Address</Typography>
            <Box
              display="flex"
              alignItems="center"
              sx={{ justifyContent: "flex-end" }}
            >
              {/* Fixed Width Container for Value */}
              <Typography
                variant="h6"
                sx={{
                  color: "text.secondary",
                  fontSize: { md: 12, lg: 12, xl: 14 },
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  minWidth: "200px", // Set consistent width
                  textAlign: "right", // Align text to the right within this space
                }}
              >
                {formData.ipAddress}
              </Typography>
              {/* Icon for copy to clipboard */}
              <Tooltip title="Copy IP Address">
                <IconButton
                  onClick={() => handleCopy(formData.ipAddress, "IP Address")}
                  aria-label="copy-ip-address"
                >
                  <ContentCopyIcon sx={{ fontSize: 18 }} />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>

          <Box display="flex" justifyContent="space-between" sx={{ mb: 1 }}>
            <Typography sx={{ fontWeight: "bold" }}>Last updated</Typography>
            <Typography
              variant="h6"
              sx={{
                color: "text.secondary",
                paddingTop: 1,
                paddingLeft: 2,
                fontSize: { md: 12, lg: 12, xl: 14 },
              }}
              noWrap
            >
              {convertToCET(formData.lastUpdated)}
            </Typography>
          </Box>

          <Box display="flex" justifyContent="space-between" sx={{ mb: 1 }}>
            <Typography sx={{ fontWeight: "bold" }}>Status</Typography>
            {editMode ? (
              <Box display="flex" alignItems="center">
                <Switch
                  checked={formData.status}
                  onChange={handleStatusToggle}
                  color="primary"
                />
                <Typography
                  variant="h6"
                  sx={{
                    color: "text.secondary",
                    paddingTop: 1,
                    paddingLeft: 2,
                    fontSize: { md: 12, lg: 12, xl: 14 },
                  }}
                  noWrap
                >
                  {formData.status ? "Online" : "Offline"}
                </Typography>
              </Box>
            ) : (
              <Typography
                variant="h6"
                sx={{
                  color: "text.secondary",
                  paddingTop: 1,
                  paddingLeft: 2,
                  fontSize: { md: 12, lg: 12, xl: 14 },
                }}
                noWrap
              >
                {formData.status ? "Online" : "Offline"}
              </Typography>
            )}
          </Box>

          {/* Device Name Field */}
          {editMode && (
            <Box display="flex" justifyContent="space-between" sx={{ mb: 1 }}>
              <Typography sx={{ fontWeight: "bold" }}>Device Name</Typography>
              <InputTextField
                placeholder="Enter Device Name"
                id="deviceName"
                label="Device Name"
                width={300}
                value={formData.deviceName}
                onChange={(e) => handleInputChange(e, "deviceName")}
                error={boardNameError} // Add error logic if necessary
                helperText={
                  boardNameError ? boardNameErrorMsg : "Enter device name"
                }
              />
            </Box>
          )}
        </Box>

        {/* Divider between sections */}
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            margin: "0 16px",
            borderStyle: "dotted", // Dotted divider style
            borderColor: "gray", // Divider color
            borderWidth: "1px", // Divider thickness
          }}
        />
        {/* Right Column */}
        <Box flex={1} ml={4}>
          <Box display="flex" justifyContent="space-between" sx={{ mb: 1 }}>
            <Typography sx={{ fontWeight: "bold" }}>Pi user</Typography>
            {editMode ? (
              <InputTextField
                placeholder="Enter Pi user"
                id="piUser"
                label="Pi User"
                width={300}
                value={formData.piUser}
                onChange={handleInputChange}
                error={piUserError}
                helperText={piUserError ? piUserErrorMsg : "Enter Pi user"}
              />
            ) : (
              <Box display="flex" justifyContent="space-between">
                <Typography
                  variant="h6"
                  sx={{
                    color: "text.secondary",
                    paddingTop: 1,
                    paddingLeft: 2,
                    fontSize: { md: 12, lg: 12, xl: 14 },
                  }}
                  noWrap
                >
                  {formData.piUser}
                </Typography>
                {/* Icon for copy to clipboard */}
                <Tooltip title="Copy pi user">
                  <IconButton
                    onClick={() => handleCopy(formData.piUser, "pi user")}
                    aria-label="copy-pi-user"
                  >
                    <ContentCopyIcon sx={{ fontSize: 18 }} />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Box>

          <Box display="flex" justifyContent="space-between" sx={{ mb: 1 }}>
            <Typography sx={{ fontWeight: "bold" }}>Owner</Typography>
            {editMode ? (
              <InputTextField
                placeholder="Enter owner's name"
                id="owner"
                label="Owner"
                width={300}
                value={formData.owner}
                onChange={handleInputChange}
                error={ownerNameError}
                helperText={
                  ownerNameError ? ownerNameErrorMsg : "Enter owner's name"
                }
              />
            ) : (
              <Typography
                variant="h6"
                sx={{
                  color: "text.secondary",
                  paddingTop: 1,
                  paddingLeft: 2,
                  fontSize: { md: 12, lg: 12, xl: 14 },
                }}
                noWrap
              >
                {formData.owner}
              </Typography>
            )}
          </Box>

          <Box display="flex" justifyContent="space-between" sx={{ mb: 1 }}>
            <Typography sx={{ fontWeight: "bold" }}>Team</Typography>
            {editMode ? (
              <InputSelectList
                name="teamname"
                label="Team Name"
                value={formData.teamid ? formData.teamid : ""}
                onChange={(e) => handleTeamChange(e, "teamid")}
                values={teamsList} // Pass the teams list as the select options
                helperText={teamError ? teamErrorMsg : "Team owning this Pi"}
                error={teamError} // Show error if no team is selected
                width={300}
              />
            ) : (
              <Typography
                variant="h6"
                sx={{
                  color: "text.secondary",
                  paddingTop: 1,
                  paddingLeft: 2,
                  fontSize: { md: 12, lg: 12, xl: 14 },
                }}
                noWrap
              >
                {formData.team}
              </Typography>
            )}
          </Box>

          <Box display="flex" justifyContent="space-between" sx={{ mb: 1 }}>
            <Typography sx={{ fontWeight: "bold" }}>Device Location</Typography>
            {editMode ? (
              <Box
                display="flex"
                flexDirection="column"
                gap={2}
                width="100%"
                alignItems="flex-end"
                sx={{ mt: 2 }}
              >
                {/* Office Name Selector */}
                <InputSelectList
                  name="officeName"
                  label="Office Name"
                  value={formData.officeId}
                  width={300}
                  onChange={(e) => handleOfficeDetailChange(e, "officeId")}
                  values={officeList.map((office) => ({
                    value: office.officeId,
                    text: office.officeName,
                  }))}
                  helperText={
                    officeNameError ? officeNameErrorMsg : "Select Office Name"
                  }
                  error={officeNameError}
                  sx={{ mb: 2 }} // Add margin-bottom for spacing between fields
                />

                {/* Board Floor Selector */}
                <InputSelectList
                  name="deviceFloor"
                  label="Device Floor"
                  width={300}
                  value={formData.deviceFloor}
                  onChange={(e) => handleOfficeDetailChange(e, "deviceFloor")}
                  values={floorListValues}
                  helperText={
                    boardFloorError ? boardFloorErrorMsg : "Select Board Floor"
                  }
                  error={boardFloorError}
                  sx={{ mb: 2 }} // Add margin-bottom for spacing between fields
                />
              </Box>
            ) : (
              <Typography
                variant="h6"
                sx={{
                  color: "text.secondary",
                  paddingTop: 1,
                  paddingLeft: 2,
                  fontSize: { md: 12, lg: 12, xl: 14 },
                }}
                noWrap
              >
                {`${formData.officeName}, ${formData.officeLocation}, Floor - ${formData.deviceFloor}`}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      {/* Save and Cancel Buttons */}
      {editMode && (
        <Box
          display="flex"
          gap={2}
          mt={3}
          position="absolute"
          left={20}
          bottom={10}
        >
          {/* Save Button */}
          <Button
            type="submit"
            variant="contained"
            startIcon={isUpdating ? null : <Iconify icon="eva:plus-fill" />}
            disabled={!formIsValid || isUpdating}
            onClick={submitHandler} // Trigger the form submission
          >
            {isUpdating ? "Updating..." : "Save"}
          </Button>
          {/* Cancel Button */}
          <Button variant="contained" onClick={handleCancel}>
            Cancel
          </Button>
        </Box>
      )}
      {/* Edit Icon */}
      {!editMode && (
        <IconButton
          color="primary"
          onClick={toggleEditMode}
          sx={{
            position: "absolute",
            top: 20,
            right: 20,
          }}
        >
          <Edit />
        </IconButton>
      )}
    </Box>
  );
};

export default DeviceForm;
