import * as React from "react";
import { useState, useEffect, useCallback, useContext } from "react";
/* import { useMsal } from "@azure/msal-react"; */

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { sentenceCase } from "change-case";

import { filter } from "lodash";
import { useNavigate } from "react-router-dom";
// @mui
import {
  Box,
  Card,
  Collapse,
  Table,
  Stack,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  ListItemButton,
  ListItemText,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { accessTokenForApi } from "../../../authentication/authConfig";
import Scrollbar from "../../scrollbar/Scrollbar";
import Label from "../../label/Label";
import Iconify from "../../iconify/Iconify";
import OfficeContext from "../offices/store/office-context";
// sections
import {
  DashboardsListHead,
  DashboardsListToolbar,
} from "../../sections/@dashboard/dashboards";

const TABLE_HEAD = [
  { id: "devicename", label: "Device Name", alignRight: false },
  { id: "teamname", label: "Team Name", alignRight: false },
  { id: "officename", label: "Office Name", alignRight: false },
  { id: "location", label: "Location", alignRight: false },
  { id: "floor", label: "Floor", alignRight: false },
  { id: "owner", label: "Owner", alignRight: false },
  { id: "ipaddress", label: "IP Address", alignRight: false },
  { id: "piuser", label: "Pi User", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "" },
];

const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

//--------------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_device) => {
      const searchString = query.toLowerCase();
      return (
        _device.devicename.toLowerCase().includes(searchString) ||
        _device.ipaddress.toLowerCase().includes(searchString) ||
        _device.teamname.toLowerCase().includes(searchString) ||
        _device.officename.toLowerCase().includes(searchString) ||
        _device.location.toLowerCase().includes(searchString) ||
        _device.owner.toLowerCase().includes(searchString) ||
        _device.piuser.toLowerCase().includes(searchString) ||
        _device.status.toLowerCase().includes(searchString)
      );
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
};

//------------------------------------------------------------------------------------

export default function ScreensInUse(props) {
  const navigate = useNavigate();
  const ctx = useContext(OfficeContext);
  /*  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount(); */
  const [expandedScreensInUse, setExpandedScreensInUse] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [inUseScreensList, setInUseScreensList] = useState([]);
  const [fetchInUseScreensListError, setInUseScreensListError] = useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("devicename");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterName, setFilterName] = useState("");
  //eslint-disable-next-line
  const [copySuccess, setCopySuccess] = useState("");

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - inUseScreensList.length)
      : 0;

  let filteredScreensInUse = applySortFilter(
    inUseScreensList,
    getComparator(order, orderBy),
    filterName
  );

  if (ctx.isRedirectFromOffice) {
    filteredScreensInUse = filteredScreensInUse
      .filter((office) => {
        return (
          ctx.officeName.length === 0 || ctx.officeName === office.officename
        );
      })
      .filter((office) => {
        return (
          ctx.selectedLocation.length === 0 ||
          ctx.selectedLocation.some((f) => {
            return f === office.location;
          })
        );
      });
  } else {
    filteredScreensInUse = filteredScreensInUse.filter((team) => {
      return (
        props.selectedTeam &&
        (props.selectedTeam.length === 0 ||
          props.selectedTeam.some((f) => {
            return f === team.teamname;
          }))
      );
    });
  }

  const isNotFound = !filteredScreensInUse.length && !!filterName;

  const handleExpandListClickInUseBScreensList = () => {
    setExpandedScreensInUse(!expandedScreensInUse);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = inUseScreensList.map((n) => n.piuser);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleRowClick = (rowData) => {
    const id = rowData.id;
    navigate(`/dashboard/device/${id}`, {
      state: {
        rowData,
      },
    });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  // Handle Copy to Clipboard
  const handleCopy = (text, fieldName) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopySuccess(`${fieldName} copied!`);
        setTimeout(() => setCopySuccess(""), 2000); // Clear success message after 2 seconds
      })
      .catch(() => {
        setCopySuccess(`Failed to copy ${fieldName}.`);
        setTimeout(() => setCopySuccess(""), 2000);
      });
  };

  //-------------Fetch pi deivces in use list from DB-------------------------------------

  const fetchScreensList = useCallback(async () => {
    setIsLoading(true);
    const authenticatedValue = await accessTokenForApi();
    try {
      const response = await fetch(BASE_URL + "/board/getAllDevices", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authenticatedValue.accessToken,
        },
      });
      if (!response.ok) {
        throw new Error("Something went wrong");
      }
      const data = await response.json();
      const transformedList = data.resultSet.data.map((listData) => {
        return {
          status: (!listData.active && "inactive") || "active",
          id: listData.board_id,
          devicename: listData.board_name,
          teamname: listData.team_name,
          officename: listData.office_name,
          location: listData.office_location,
          country: listData.office_country,
          floor: listData.board_floor,
          owner: listData.board_owner,
          piuser: listData.pi_user,
          lastupdatedat: listData.updated_at,
          ipaddress: listData.ip_address,
          officeid: listData.office_id,
          teamid: listData.team_id,
        };
      });
      setInUseScreensList(transformedList);
      setIsLoading(false);
    } catch (error) {
      setInUseScreensListError(error.message);
      console.log(fetchInUseScreensListError);
    }
    // eslint-disable-next-line
  }, [fetchInUseScreensListError]);

  useEffect(() => {
    fetchScreensList();
  }, [fetchScreensList, props.reFetchData]);

  return (
    <Card
      sx={{
        mt: 3,
        padding: 2,
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <ListItemButton onClick={handleExpandListClickInUseBScreensList}>
          <ListItemText
            disableTypography
            primary={<Typography variant="h6">Pi devices in use</Typography>}
          />
          {expandedScreensInUse ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemButton>
      </Stack>
      <Collapse in={expandedScreensInUse} timeout="auto" unmountOnExit>
        <DashboardsListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
          searchComponent="Pi"
        />

        {isLoading ? (
          <Box sx={{ flexGrow: 1, textAlign: "center", paddingTop: 7 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <DashboardsListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={inUseScreensList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredScreensInUse
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        id,
                        devicename,
                        teamname,
                        officename,
                        floor,
                        owner,
                        location,
                        // eslint-disable-next-line
                        country,
                        piuser,
                        status,
                        // eslint-disable-next-line
                        lastupdatedat,
                        // eslint-disable-next-line
                        ipaddress,
                        // eslint-disable-next-line
                        officeid,
                        // eslint-disable-next-line
                        teamid,
                      } = row;
                      const selectedDashboard = selected.indexOf(id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="button"
                          selected={selectedDashboard}
                          sx={{
                            cursor: "pointer",
                          }}
                          onClick={() => handleRowClick(row)}
                        >
                          <TableCell component="th" scope="row" padding="none">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Typography variant="h6" paddingLeft={2} noWrap>
                                {truncateText(devicename, 22)}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{teamname}</TableCell>
                          <TableCell align="left">{officename}</TableCell>
                          <TableCell align="left">{location}</TableCell>
                          <TableCell align="left">
                            {" "}
                            {truncateText(floor, 4)}
                          </TableCell>
                          <TableCell align="left">{owner}</TableCell>
                          <TableCell align="left">
                            <Box display="flex" alignItems="center">
                              {ipaddress}
                              <Tooltip title="Copy IP Address">
                                <IconButton
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleCopy(ipaddress, "IP Address");
                                  }}
                                  aria-label="copy-ip-address"
                                >
                                  <ContentCopyIcon sx={{ fontSize: 18 }} />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </TableCell>
                          <TableCell align="left">{piuser}</TableCell>
                          <TableCell align="left">
                            <Label
                              color={
                                (status === "inactive" && "error") || "success"
                              }
                            >
                              {sentenceCase(status)}
                            </Label>
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={(event) =>
                                props.onPopOverMenuClick(event, row)
                              }
                            >
                              <Iconify icon={"eva:more-vertical-fill"} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete
                            words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        )}
      </Collapse>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredScreensInUse.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
}
