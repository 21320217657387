//React modules
import { Fragment } from "react";
import { Helmet } from "react-helmet-async";
//import { useLoaderData, json } from "react-router-dom";
import { useState, useEffect } from "react";
// @mui
import { Grid, Typography, Box, CircularProgress } from "@mui/material";
import { useMsal } from "@azure/msal-react";
//components
//import Searchbar from "../../UI/Header/SearchBar";
import HomeSummary from "../../sections/@dashboard/App/HomeSummary";
import AppLatestUpdate from "../../sections/@dashboard/App/AppLatestUpdate";
import { accessTokenForApi } from "../../../authentication/authConfig";
// ----------------------------------------------------------------------

//Global constants
const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;
// ----------------------------------------------------------------------

const UserLoggedInLandingPage = (props) => {
  const [officeCount, setOfficeCount] = useState(0);
  const [activeScreenCount, setActiveScreenCount] = useState(0);
  const [boardsCount, setBoardsCount] = useState(0);
  const [teamsCount, setTeamsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [recentActivities, setRecentActivities] = useState([]);
  //-------------------------------------------------------------------------------
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  //-------------------------------------------------------------------------------

  const fetchCounts = async () => {
    setIsLoading(true);
    const authenticatedValue = await accessTokenForApi();
    // console.log("Token");
    // console.log(authenticatedValue.accessToken);
    Promise.all([
      fetch(BASE_URL + "/office/getOfficeCount", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authenticatedValue.accessToken,
        },
      }),
      fetch(BASE_URL + "/board/getActiveBoardCount", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authenticatedValue.accessToken,
        },
      }),
      fetch(BASE_URL + "/board/getBoardCount", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authenticatedValue.accessToken,
        },
      }),
      fetch(BASE_URL + "/team/getTeamCount", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authenticatedValue.accessToken,
        },
      }),
    ])
      .then(function (responses) {
        return Promise.all(
          responses.map(function (response) {
            setIsLoading(false);
            return response.json();
          })
        );
      })
      .then(function (data) {
        setOfficeCount(data[0].resultSet);
        setActiveScreenCount(
          data[1].resultSet === 0 || data[1].resultSet === undefined
            ? 0
            : data[1].resultSet
        );
        setBoardsCount(data[2].resultSet);
        setTeamsCount(data[3].resultSet);
      })
      .catch(function (error) {
        setIsLoading(false);

        console.log(error);
      });
  };

  const fetchRecentOperations = async () => {
    setIsLoading(true);
    const authenticatedValue = await accessTokenForApi();
    try {
      const response = await fetch(
        BASE_URL + "/operations/listOperationsPerformed",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authenticatedValue.accessToken,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Something went wrong");
      }
      const data = await response.json();
      const transformedList = data.resultSet.map((listData) => {
        return {
          id: listData.operations_id,
          type: listData.component_type,
          description:
            listData.operations_performed +
            " " +
            listData.component_type +
            " " +
            listData.component_name +
            " by " +
            listData.update_by,
          postedAt: listData.update_at,
          operation: listData.operations_performed,
        };
      });
      setRecentActivities(transformedList);
      setIsLoading(false);
    } catch (error) {}
    // eslint-disable-next-line
  };

  useEffect(() => {
    fetchCounts();
    fetchRecentOperations();
  }, []);

  //-------------------------------------------------------------------------------

  return (
    <Fragment>
      <Helmet>
        <title> Home | Dashboard Insights </title>
      </Helmet>
      {isLoading ? (
        <Box sx={{ flexGrow: 1, textAlign: "center", paddingTop: 7 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ padding: 5 }}>
          {/*           <Box sx={{ flexGrow: 1, textAlign: "right", overflow: "hidden" }}>
            <Searchbar />
          </Box> */}
          <Box sx={{ flexGrow: 1, textAlign: "left", paddingTop: 5 }}>
            <Typography variant="h5" sx={{ mb: 5 }}>
              Hi, Welcome <i>{activeAccount ? activeAccount.name : ""}</i>
            </Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <HomeSummary
                title="Offices"
                total={officeCount}
                icon={"heroicons:building-office-2"}
                component="offices"
                //error={officeCountError}
              ></HomeSummary>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <HomeSummary
                title="Teams"
                total={teamsCount}
                color="error"
                icon={"fluent:people-team-16-regular"}
                component="teams"
                //error={teamsCountError}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <HomeSummary
                title="Active Pis"
                total={activeScreenCount}
                color="active"
                icon={"ic:outline-monitor"}
                component="pi_devices"
                // error={activeScreenCountError}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <HomeSummary
                title="Total Pis"
                total={boardsCount}
                color="info"
                icon={"mdi-light:monitor-multiple"}
                component="pi_devices"
                //error={boardsCountError}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <AppLatestUpdate
                title="Recent Activities"
                list={recentActivities.map((entry, index) => ({
                  id: entry.id,
                  title: entry.type,
                  description: entry.description,
                  image: `/assets/images/recentactivitiesavatars/${
                    entry.type + "-" + entry.operation
                  }.png`,
                  postedAt: entry.postedAt,
                }))}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Fragment>
  );
};
export default UserLoggedInLandingPage;
