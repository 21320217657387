import React from "react";
import { useState } from "react";
import { useMsal } from "@azure/msal-react";
//mui
import { Button, Tooltip } from "@mui/material";
import RestartAlt from "@mui/icons-material/RestartAlt";
//Custom Components
import { accessTokenForApi } from "../../../authentication/authConfig";
import AlertDialog from "../../UI/alerts/AlertDialog";
import SuccessDialog from "../../UI/alerts/SuccessfulAdd";
import { addRecentActivity } from "../../activityTracker/activityUpdate";

const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

// Restart Device Button Component
const RestartDeviceButton = ({ deviceName, deviceId }) => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  // ---------------------- States --------------------------

  //Restart Button states
  const [restartConfirm, SetRestartConfirm] = useState(false);
  const [restartDeviceError, setRestartDeviceError] = useState("");
  const [restartStatus, setRestartStatus] = useState(false);

  //----------------------- Handlers -----------------------

  // Function to handle device restart
  const handleDeviceRestart = () => {
    SetRestartConfirm(true);
    setTimeout(() => {
      SetRestartConfirm(false);
    }, 10000);
  };
  // Function to handle device restart reset
  const handleDeviceRestartReset = () => {
    SetRestartConfirm(false);
  };

  const handleRestartStatusReset = () => {
    setRestartStatus(false);
  };

  //-----------------------Backend API Handlers -----------------------

  const restartDeviceHandler = async (deviceId, deviceName) => {
    const operation = {
      operations_performed: "Restarted",
      component_type: "dashboard",
      component_name: deviceName,
      update_by: activeAccount.name,
    };
    setRestartDeviceError(null);
    const authenticatedValue = await accessTokenForApi();
    try {
      const response = await fetch(
        BASE_URL + `/board/rebootBoard/${deviceId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authenticatedValue.accessToken,
          },
        }
      );
      const data = await response.json();
      if (data.Status === "success") {
        handleDeviceRestartReset(false);
        setRestartStatus(true);
        addRecentActivity(operation);
      }
    } catch (error) {
      setRestartDeviceError(error.message || "Something went wrong!");
      console.log(restartDeviceError);
    }
  };

  // ---------------------- Jsx for Restart Button -----------------------------
  return (
    <>
      {/* Restart confirmation Dialog */}
      {restartConfirm && (
        <AlertDialog
          open={restartConfirm}
          component="Device"
          name={deviceName}
          operation="restart"
          onCancelClick={handleDeviceRestartReset}
          onOkClick={() => restartDeviceHandler(deviceId, deviceName)}
        />
      )}
      {/* Restart success Dialog */}
      {restartStatus && (
        <SuccessDialog
          open={restartStatus}
          component="Device"
          operation="restarted"
          onOkClick={handleRestartStatusReset}
        />
      )}
      <Tooltip title="Restart Device" arrow>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#FFB74D", // Light orange
            color: "white",
            borderRadius: 2,
            paddingX: 2,
            paddingY: 1,
            fontSize: "0.875rem",
            display: "flex",
            alignItems: "center",
            textTransform: "none",
            boxShadow: 2,
            ":hover": {
              boxShadow: 4,
              backgroundColor: "#FF9800", // Darker orange on hover
            },
          }}
          onClick={handleDeviceRestart}
        >
          <RestartAlt sx={{ marginRight: 1 }} />
          Restart Device
        </Button>
      </Tooltip>
    </>
  );
};

export default RestartDeviceButton;
