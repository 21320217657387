import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { MsalProvider, useMsal } from "@azure/msal-react";
import ThemeProvider from "./theme";
import ScrollToTop from "./components/scroll-to-top/ScrollToTop";
import { StyledChart } from "./components/chart";
import routes from "./routes";

// Helper Component for Authentication
const RedirectOnAuth = ({ children }) => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  // Redirect to dashboard if authenticated
  if (activeAccount) {
    return <Navigate to="/dashboard/home" replace />;
  }

  // Otherwise, render children (e.g., HomePage for unauthenticated users)
  return children;
};

const App = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <ThemeProvider>
        <BrowserRouter>
          <ScrollToTop />
          <StyledChart />
          <Routes>
            {routes.map((route, index) => {
              if (route.path === "/") {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={
                      <RedirectOnAuth>
                        {route.element}
                      </RedirectOnAuth>
                    }
                  />
                );
              }
              if (route.children) {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.element}
                  >
                    {route.children.map((child, childIndex) => (
                      <Route
                        key={childIndex}
                        path={child.path}
                        element={child.element}
                        index={child.index || false}
                      />
                    ))}
                  </Route>
                );
              }
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={route.element}
                />
              );
            })}
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </MsalProvider>
  );
};

export default App;
