//React modules
import React from "react";
import { StatusBar } from "react-native";
import { useTheme } from "@mui/material/styles";
import SendIcon from "@mui/icons-material/Send";
import { useMsal } from "@azure/msal-react";
// @mui
import { Button, Typography, Grid, Card } from "@mui/material";
//components
import Header from "../../UI/Header/Header";
import { loginRequest } from "../../../authentication/authConfig";
//css
import classes from "./HomePage.module.css";
//assets
import HomePageIllustrator from "../../../assets/admin-panel-png.png";

// ----------------------------------------------------------------------
const HomePage = () => {
  const theme = useTheme();
  const { instance } = useMsal();

  const handleLoginButtonClick = () => {
    instance.loginRedirect(loginRequest).catch((error) => console.log(error));
  };

  return (
    <div className={classes.container}>
      <StatusBar hidden={true} />
      <Header />
      <Grid
        container
        justifyContent="center"
        sx={{ paddingTop: theme.spacing(3), height: "50vh" }}
      >
        <Card
          sx={{
            background: "transparent",
            color: "#022F8E",
            alignItems: "center",
            borderRadius: 7,
            width: "90vw",
            height: "50vh",
            boxShadow: 5,
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              flexGrow: 1,
              alignItems: "center",
              height: "50vh",
            }}
          >
            <Grid
              item
              xs={7}
              sx={{
                display: "flex",
                width: "auto",
                paddingTop: 1,
                paddingLeft: { xs: 1, md: 1, lg: 5, xl: 20 },
                alignItems: "center",
              }}
            >
              <Grid
                container
                direction="column"
                spacing={1}
                justifyContent="flex-start"
                sx={{
                  display: "flex",
                  width: "auto",
                  alignItems: "center",
                }}
              >
                <Grid item>
                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: { xs: "none", sm: 20, md: 20, lg: 15, xl: 40 },
                    }}
                  >
                    The Future of Dashboards
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      display: {
                        xs: "none",
                        md: "flex",
                        lg: "flex",
                        xl: "flex",
                      },
                      color: "#454545",
                      textDecoration: "none",
                      fontSize: { xs: "none", sm: 20, md: 20, lg: 15, xl: 18 },
                      paddingBottom: 2,
                    }}
                  >
                    An unified solution for managing your dashboard screens in offices.
                  </Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "none",
                      lg: "flex",
                      xl: "flex",
                    },
                  }}
                >
                  <Button
                    variant="contained"
                    endIcon={<SendIcon />}
                    onClick={handleLoginButtonClick}
                  >
                    Get Started
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "none",
                  lg: "flex",
                  xl: "flex",
                },
                //width: "100%",
                width: "20vw",
                height: "45vh",
                padding: 1,
              }}
              xs={5}
            >
              <img
                alt="admin panel icon"
                /* sx={{
                  width: "600vw",
                  height: "400vh",
                }} */
                src={HomePageIllustrator}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </div>
  );
};

export default HomePage;
