import React from "react";
import { useState } from "react";

import { Button, Tooltip, Modal, Fade, Box, Backdrop } from "@mui/material";
import { AddLink } from "@mui/icons-material";

//Custom components
import DeviceContext from "./store/device-context";
import AddOrUpdateUrl from "./urlsList/AddOrUpdateUrl";
import SuccessDialog from "../../UI/alerts/SuccessfulAdd";

// Style for the modal
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { md: "70vw", lg: 650, xl: 850 },
  height: { md: "60vh", lg: 350, xl: 450 },
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 54,
  p: 4,
};

// Add new URL component
const AddNewUrlButton = ({
  deviceName,
  deviceId,
  isUpdateOperation,
  onUrlAddOrUpdateStatusChange,
}) => {
  // -------------------------- States --------------------------------
  const [openAddOrUpdateUrl, SetOpenAddOrUpdateUrl] = useState(false);
  const [urlAddOrUpdateStatus, setUrlAddOrUpdateStatus] = useState(false);

  //-------------------------- Handlers -------------------------------

  const handleCloseUrlAddOrUpdate = () => {
    SetOpenAddOrUpdateUrl(false);
  };
  const handleUrlAddOrUpdateStatus = () => {
    setUrlAddOrUpdateStatus(true);
    onUrlAddOrUpdateStatusChange(true);
  };
  const handleUrlAddOrUpdateStatusReset = () => {
    setUrlAddOrUpdateStatus(false);
    onUrlAddOrUpdateStatusChange(false);
  };

  // ---------------------- Jsx for Add new URL Button --------------------
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        sx={{ width: 2000 }}
        open={openAddOrUpdateUrl}
        closeAfterTransition
        onClose={handleCloseUrlAddOrUpdate}
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openAddOrUpdateUrl}>
          <Box sx={style}>
            <DeviceContext.Provider
              value={{
                deviceId: deviceId,
                deviceName: deviceName,
              }}
            >
              <AddOrUpdateUrl
                isUpdate={isUpdateOperation}
                onCancelClick={handleCloseUrlAddOrUpdate}
                onUpdateClick={handleUrlAddOrUpdateStatus}
              />
            </DeviceContext.Provider>
          </Box>
        </Fade>
      </Modal>
      {urlAddOrUpdateStatus && (
        <SuccessDialog
          open={urlAddOrUpdateStatus}
          component="Url"
          operation="added"
          onOkClick={handleUrlAddOrUpdateStatusReset}
        />
      )}
      <Tooltip title="Add New URL" arrow>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#8A2BE2", // Violet color
            color: "white",
            borderRadius: 2,
            paddingX: 2, // Reduced padding for smaller buttons
            paddingY: 1, // Reduced padding for smaller buttons
            fontSize: "0.875rem", // Slightly smaller font size
            display: "flex",
            alignItems: "center",
            textTransform: "none", // To prevent all caps on button text
            boxShadow: 2, // Slight shadow for button
            ":hover": {
              boxShadow: 4, // Slightly stronger shadow on hover
              backgroundColor: "#7A1F98", // Darker violet on hover
            },
          }}
          onClick={() => SetOpenAddOrUpdateUrl(true)}
        >
          <AddLink sx={{ marginRight: 1 }} /> {/* Icon */}
          Add New URL
        </Button>
      </Tooltip>
    </>
  );
};

export default AddNewUrlButton;
