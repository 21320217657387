import * as React from "react";
import { Helmet } from "react-helmet-async";
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useMsal } from "@azure/msal-react";
//@Mui
import {
  CssBaseline,
  Grid,
  Stack,
  Box,
  Typography,
  FormGroup,
  FormControlLabel,
  styled,
  Switch,
  Button,
} from "@mui/material";
//component
import InputTextField from "../../../UI/FormInput/InputTextField";
import Iconify from "../../../iconify/Iconify";
import DeviceContext from "../store/device-context";
import { accessTokenForApi } from "../../../../authentication/authConfig";
import { addRecentActivity } from "../../../activityTracker/activityUpdate";
//------------------------------------------------------------------------

const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

const StatusSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

//---------------------------------------------------------------------------------------------

const AddOrUpdateUrl = (props) => {
  const navigate = useNavigate();

  const ctx = useContext(DeviceContext);

  const { instance } = useMsal();

  const activeAccount = instance.getActiveAccount();

  const theme = useTheme();

  const operation = props.isUpdate;
  //const operation = false;

  const [title, setTitle] = useState(operation ? props.datas.urltitle : "");

  const [updateInterval, setUpdateInterval] = useState(
    operation ? props.datas.updateinterval : ""
  );

  const [url, setUrl] = useState(operation ? props.datas.urllink : "");

  let checkedStatus = false;
  if (operation) {
    checkedStatus = props.datas.status;
  }

  const [checked, setChecked] = useState(checkedStatus);

  const [titleError, setTitleError] = useState(false);

  const [titleErrorMsg, settitleErrorMsg] = useState("");

  const [updateIntervalError, setUpdateIntervalError] = useState(false);

  const [updateIntervalErrorMsg, setUpdateIntervalErrorMsg] = useState("");

  const [urlError, setUrlError] = useState(null);

  const [urlErrorMsg, setUrlErrorMsg] = useState("");

  const [addUrlError, setAddUrlError] = useState(null);

  const [urlAlreadyExist, setUrlAlreadyExist] = useState(false);

  const isUpdating = navigate.state === "submitting";
  const currrentBoardId = ctx.deviceId;
  const currrentBoardName = ctx.deviceName;
  const currentUrlId = operation ? props.datas.id : "";
  let formIsValid = false;
  if (
    title &&
    updateInterval &&
    url &&
    !titleError &&
    !updateIntervalError &&
    !urlError
  ) {
    formIsValid = true;
  }

  const submitHandler = (event) => {
    event.preventDefault();
    setTitleError(false);
    setUpdateIntervalError(false);
    setUrlError(false);
    if (title === "") {
      setTitleError(true);
    }
    if (updateInterval === "") {
      setUpdateIntervalError(true);
    }
    if (url === "") {
      setUrlError(true);
    }
    {
      const urlDetails = {
        board_id: currrentBoardId,
        board_url_id: currentUrlId,
        url_title: title,
        readable_url_link: url,
        update_interval: updateInterval,
        updated_by: activeAccount ? activeAccount.username : "",
        active: checked,
      };
      formIsValid = true;
      if (operation) {
        updateUrlHandler(urlDetails);
      } else {
        addUrlHandler(urlDetails);
      }
    }
  };

  const addUrlHandler = async (urlDetails) => {
    const operation = {
      operations_performed: "Added",
      component_type: "url",
      component_name: urlDetails.url_title + " in board - " + currrentBoardName,
      update_by: activeAccount.name,
    };
    setAddUrlError(null);
    try {
      const authenticatedValue = await accessTokenForApi();
      const response = await fetch(BASE_URL + "/url/addNewUrl", {
        method: "POST",
        body: JSON.stringify(urlDetails),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authenticatedValue.accessToken,
        },
      });
      if (response.status === 409) {
        setUrlError(true);
        setUrlAlreadyExist(true);
      }
      const data = await response.json();
      if (data.Status === "success") {
        props.onCancelClick();
        props.onUpdateClick();
        addRecentActivity(operation);
      }
    } catch (error) {
      setAddUrlError(error.message || "Something went wrong!");
      console.log(addUrlError);
    }
  };

  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };
  const titleChangeHandler = (event) => {
    if (urlAlreadyExist) {
      setUrlAlreadyExist(false);
    }
    if (event.target.value && !event.target.value.match(/^[a-zA-Z0-9- ]+$/)) {
      setTitleError(true);
      settitleErrorMsg("Only letters and numbers allowed");
    } else {
      setTitleError(false);
      settitleErrorMsg("");
    }
    setTitle(event.target.value);
  };
  const intervalChangeHandler = (event) => {
    if (event.target.value && !event.target.value.match(/^[0-9]+$/)) {
      setUpdateIntervalError(true);
      setUpdateIntervalErrorMsg("Only numbers are allowed");
    } else {
      setUpdateIntervalError(false);
      setUpdateIntervalErrorMsg("");
    }
    setUpdateInterval(event.target.value);
  };

  const urlChangeHandler = (event) => {
    if (
      event.target.value &&
      !event.target.value.match(
        /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/i
      )
    ) {
      setUrlError(true);
      setUrlErrorMsg("Invalid URL");
    } else {
      setUrlError(false);
      setUrlErrorMsg("");
    }
    setUrl(event.target.value);
  };

  const updateUrlHandler = async (urlDetails) => {
    const operation = {
      operations_performed: "Updated",
      component_type: "url",
      component_name: urlDetails.url_title + " in board - " + currrentBoardName,
      update_by: activeAccount.name,
    };
    setAddUrlError(null);
    try {
      const authenticatedValue = await accessTokenForApi();
      const response = await fetch(BASE_URL + "/url/updateUrl", {
        method: "PUT",
        body: JSON.stringify(urlDetails),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authenticatedValue.accessToken,
        },
      });
      const data = await response.json();
      if (data.Status === "success") {
        props.onCancelClick();
        props.onUpdateClick();
        addRecentActivity(operation);
      }
    } catch (error) {
      setAddUrlError(error.message || "Something went wrong!");
      console.log(addUrlError);
    }
  };

  return (
    <>
      <Helmet>
        <title> Add URL | Device </title>
      </Helmet>
      <CssBaseline />
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Typography
            variant="h4"
            noWrap
            sx={{
              display: { xs: "none", md: "flex" },
              fontFamily: "Roboto",
              fontWeight: 500,
              fontSize: { xs: "none", md: 16, lg: 20, xl: 22 },
              letterSpacing: "0rem",
              color: "text.primary",
              textDecoration: "none",
            }}
          >
            {operation ? "Update URL details" : "Add new url"}
          </Typography>
        </Grid>
        <Grid item>
          <Grid
            container
            sx={{
              columnGap: { xs: "none", md: 0, lg: 1, xl: 1 },
            }}
          >
            <Grid item sx={{ marginTop: { xl: 2 } }}>
              <Box sx={{ width: 500 }}>
                <form autoComplete="off" onSubmit={submitHandler}>
                  <Stack direction="row">
                    <InputTextField
                      placeholder="Enter url title"
                      id="title"
                      label="Url title"
                      width={300}
                      helperText={
                        titleError ? titleErrorMsg : "Title for the dashboard"
                      }
                      defaultValue={title ? title : ""}
                      onChange={titleChangeHandler}
                      error={titleError}
                    />
                    <InputTextField
                      placeholder="Enter update interval"
                      id="interval"
                      width={160}
                      label="Update Interval"
                      helperText={
                        updateIntervalError
                          ? updateIntervalErrorMsg
                          : "Refresh interval (s)"
                      }
                      defaultValue={updateInterval ? updateInterval : ""}
                      onChange={intervalChangeHandler}
                      error={updateIntervalError}
                    />
                  </Stack>
                  <Stack direction="row">
                    <InputTextField
                      placeholder="Enter Url"
                      id="url"
                      label="Url"
                      helperText={urlError ? urlErrorMsg : "Url to the board"}
                      width={460}
                      defaultValue={url ? url : ""}
                      onChange={urlChangeHandler}
                      rows={3}
                      multiline
                      //onBlur={piuserBlurHandler}
                      error={urlError}
                    />
                  </Stack>

                  <Stack direction="row" spacing={2}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <StatusSwitch
                            sx={{ m: 2 }}
                            checked={checked}
                            onChange={toggleChecked}
                          />
                        }
                        label={`${checked ? "Active" : "Inactive"}`}
                      />
                    </FormGroup>
                  </Stack>
                  <Stack direction="row" ml={1} mt={1} spacing={10}>
                    <Button
                      type="submit"
                      variant="contained"
                      startIcon={<Iconify icon="eva:plus-fill" />}
                      disabled={!formIsValid || isUpdating}
                    >
                      {operation ? "Update" : "Add"}
                    </Button>
                    <Button variant="contained" onClick={props.onCancelClick}>
                      Cancel
                    </Button>
                  </Stack>
                </form>
              </Box>
              {urlAlreadyExist && (
                <Typography
                  variant="caption"
                  pt={5}
                  gutterBottom
                  sx={{
                    color: "#FF0000",
                    //fontSize: { xs: "none", md: 16, lg: 22, xl: 22 },
                  }}
                >
                  URL already exist. Please try with a unique URL
                </Typography>
              )}
            </Grid>

            <Grid item mt={3}>
              <Box
                component="img"
                alt="dashboardIcon"
                src="/assets/images/illustrators/add_dashboard.jpg"
                sx={{
                  [theme.breakpoints.up("sm")]: {
                    display: "none",
                    width: "20vw",
                    height: "33vh",
                  },
                  [theme.breakpoints.up("md")]: {
                    display: "none",
                    width: "20vw",
                    height: "33vh",
                  },
                  [theme.breakpoints.up("lg")]: {
                    display: "none",
                    width: 200,
                    height: 170,
                  },

                  [theme.breakpoints.up("xl")]: {
                    display: "flex",
                    width: 220,
                    height: 190,
                  },

                  flexShrink: 2,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AddOrUpdateUrl;
