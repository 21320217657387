import { useState, useCallback, useContext, useEffect } from "react";
import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useMsal } from "@azure/msal-react";
// @mui
import {
  Box,
  Stack,
  Card,
  FormGroup,
  FormControlLabel,
  Button,
  Divider,
  Typography,
  Popover,
  MenuItem,
  CardHeader,
  /* Checkbox, */
  TablePagination,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Modal,
  TableRow,
  Paper,
  Fade,
  Backdrop,
  styled,
  Switch,
  CircularProgress,
  Tooltip,
} from "@mui/material";
// components
import Iconify from "../../../iconify/Iconify";
import Scrollbar from "../../../scrollbar/Scrollbar";
import AddOrUpdateUrl from "./AddOrUpdateUrl";
/* import Label from "../../../label/Label"; */
import DeviceContext from "../store/device-context";
import SuccessDialog from "../../../UI/alerts/SuccessfulAdd";
import AlertDialog from "../../../UI/alerts/AlertDialog";
import { accessTokenForApi } from "../../../../authentication/authConfig";
import { addRecentActivity } from "../../../activityTracker/activityUpdate";
// utils
import {
  DashboardsListHead,
  DashboardsListToolbar,
} from "../../../sections/@dashboard/dashboards";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "urltitle", label: "Dashboard Title", alignRight: false },
  { id: "url", label: "URL", alignRight: false },
  { id: "interval", label: "Update Interval", alignRight: false },
  { id: "updatedby", label: "Updated by", alignRight: false },
  { id: "lastupdated", label: "Last updated at", alignRight: false },
  { id: "status", label: "Status", alignCenter: true },
  { id: "eyeicon", alignRight: false },
  { id: "" },
];

const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { md: "70vw", lg: "60vw", xl: 850 },
  height: { md: "60vh", lg: "50vh", xl: 450 },
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 54,
  p: 4,
};

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#808080", // grey
  color: "white",
  borderRadius: "22px",
  padding: "5px 10px", // Adjust padding to reduce button size
  height: "38px", // Set a fixed height to match the circle
  display: "flex",
  alignItems: "center",
  "&:hover": {
    backgroundColor: "#696969", // darker grey
  },
  paddingLeft: "0", // Remove left padding
  justifyContent: "flex-start", // Align content to the start
}));

const EyeIconWrapper = styled("div")(({ theme }) => ({
  backgroundColor: "white",
  borderRadius: "50%",
  width: "36px", // Increase width to match button height
  height: "36px", // Increase height to match button height
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginLeft: "0", // Remove margin to ensure no gap
  marginRight: "10px", // Add margin to the right for spacing
}));
//-----------------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.urltitle.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const StatusSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

// Function to convert date to CET timezone
const convertToCET = (dateString) => {
  const date = new Date(dateString);

  // Format the date in CET (Central European Time) with timezone
  const options = {
    year: "numeric", // Full year (e.g., 2024)
    month: "long", // Full month name (e.g., December)
    day: "numeric", // Day of the month (e.g., 23)
    hour: "2-digit", // Hour (e.g., 19)
    minute: "2-digit", // Minute (e.g., 14)
    second: "2-digit", // Second (e.g., 58)
    timeZoneName: "short", // Abbreviated timezone (e.g., CET)
    hour12: false, // Use 24-hour clock
    timeZone: "CET", // Set to Central European Time
  };
  const cetDate = date.toLocaleString("en-GB", options); // "en-GB" gives European style (day/month/year)
  return cetDate;
};

export default function UrlListManager(props) {
  const ctx = useContext(DeviceContext);

  const { instance } = useMsal();

  const activeAccount = instance.getActiveAccount();

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("urltitle");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [urlList, setUrlList] = useState([]);

  const [fetchUrlListError, setFetchUrlListError] = useState([]);

  const [dataInRow, setDataInRow] = useState([]);

  const [openAddOrUpdateUrl, SetOpenAddOrUpdateUrl] = useState(false);

  const [isUpdateOperation, setIsUpdateOperation] = useState(false);

  const [urlAddOrUpdateStatus, setUrlAddOrUpdateStatus] = useState(false);

  const [urlViewChangeStatus, setUrlViewChangeStatus] = useState(false);

  const [urlDeleteStatus, setUrlDeleteStatus] = useState(false);

  const [openDeleteUrlConfirm, SetOpenDeleteUrlConfirm] = useState(false);

  const [openUrlStatusUpdateConfirm, SetOpenUrlStatusUpdateConfirm] =
    useState(false);

  const [urlStatusUpdate, setUrlStatusUpdate] = useState(false);

  const [selectedRowUrlId, SetSelectedRowUrlId] = useState("");

  const [selectedRowUrlName, SetSelectedRowUrlName] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [urlStatusUpdateOperation, setUrlStatusUpdateOperation] = useState("");

  const [urlDetailsState, setUrlDetailsState] = useState([]);

  //const [checked, setChecked] = useState(false);

  //const [reFetchData, setReFetchData] = useState(false);

  //-----------------------------------------------------------------------------------

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - urlList.length) : 0;

  const filteredUrls = applySortFilter(
    urlList,
    getComparator(order, orderBy),
    filterName
  );
  const isNotFound = !filteredUrls.length && !!filterName;

  //-----------------------------------------------------------------------------------

  //--------------------Fetch URLS------------------------------------

  const fetchUrlList = useCallback(async () => {
    setIsLoading(true);
    const authenticatedValue = await accessTokenForApi();
    try {
      const response = await fetch(
        BASE_URL + `/url/getBoardUrl/${ctx.deviceId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authenticatedValue.accessToken,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Something went wrong");
      }
      const data = await response.json();
      const transformedList = data.resultSet.map((listData) => {
        return {
          status: listData.active,
          id: listData.board_url_id,
          urltitle: listData.url_title,
          currentshowingurl: listData.current_showing_url,
          urllink: listData.readable_link,
          updateinterval: listData.update_interval,
          updatedat: listData.updated_at,
          updatedby: listData.updated_by,
        };
      });
      setUrlList(transformedList);
      setIsLoading(false);
    } catch (error) {
      setFetchUrlListError(error.message);
      console.log(fetchUrlListError);
    }
    // eslint-disable-next-line
  }, [
    fetchUrlListError,
    urlAddOrUpdateStatus,
    props.urlAddStatus,
    urlDeleteStatus,
  ]);

  //-----------------------------------------------------------------------------

  const handleChangeViewUrl = (urlId) => {
    changeViewUrl(urlId);
  };

  const openDeleteUrlConfirmResetHandler = () => {
    SetOpenDeleteUrlConfirm(false);
  };

  const openUrlStatusUpdateConfirmResetHandler = () => {
    SetOpenUrlStatusUpdateConfirm(false);
  };

  const urlStatusUpdateHandler = () => {
    setUrlStatusUpdate(true);
  };

  const urlViewChangeStatusResetHandler = () => {
    setUrlViewChangeStatus(false);
  };
  const urlAddOrUpdateStatusResetHandler = () => {
    setUrlAddOrUpdateStatus(false);
    SetOpenAddOrUpdateUrl(false);
  };
  const urlAddOrUpdateStatusHandler = () => {
    setUrlAddOrUpdateStatus(true);
    SetOpenAddOrUpdateUrl(true);
  };
  const handleOpenUrlAddOrUpdate = () => {
    SetOpenAddOrUpdateUrl(true);
  };
  const handleCloseUrlAddOrUpdate = () => {
    SetOpenAddOrUpdateUrl(false);
    setOpen(null);
  };
  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleOpenMenu = (event, id, name, rowData) => {
    setOpen(event.currentTarget);
    SetSelectedRowUrlId(id);
    SetSelectedRowUrlName(name);
    setDataInRow(rowData);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = urlList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  /*  const handleCheckboxClick = (e) => {
    e.stopPropagation();
  }; */

  const handleRowClick = (row) => {
    setIsUpdateOperation(true);
    setDataInRow(row);
    handleOpenUrlAddOrUpdate();
  };

  const handleEditClick = () => {
    setIsUpdateOperation(true);
    handleOpenUrlAddOrUpdate();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleDeleteUrlClick = () => {
    SetOpenDeleteUrlConfirm(true);
    setOpen(null);
  };

  const urlDeleteStatusHandler = () => {
    setUrlDeleteStatus(true);
  };

  const urlDeleteStatusResetHandler = () => {
    setUrlDeleteStatus(false);
    setSelected([]);
  };

  const urlStatusUpdateResetHandler = () => {
    setUrlStatusUpdate(false);
    fetchUrlList();
  };

  //--------------------Change URL------------------------------------

  const changeViewUrl = async (urlId) => {
    try {
      const authenticatedValue = await accessTokenForApi();
      const response = await fetch(
        BASE_URL + `/url/changeUrl/${ctx.deviceId},${urlId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authenticatedValue.accessToken,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Something went wrong");
      }
      const data = await response.json();
      if (data.Status === "success") {
        setUrlViewChangeStatus(true);
      }
    } catch (error) {
      console.log(error.message);
    }
    // eslint-disable-next-line
  };

  //--------------------Delete URL------------------------------------

  const deleteUrl = async (urlId, urlName) => {
    const operation = {
      operations_performed: "Deleted",
      component_type: "url",
      component_name: urlName + " in board - " + ctx.deviceName,
      update_by: activeAccount.name,
    };
    try {
      const authenticatedValue = await accessTokenForApi();
      const response = await fetch(
        BASE_URL + `/url/deleteBoardurl/${ctx.deviceId},${urlId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authenticatedValue.accessToken,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Something went wrong");
      }
      const data = await response.json();
      if (data.Status === "success") {
        openDeleteUrlConfirmResetHandler();
        urlDeleteStatusHandler();
        addRecentActivity(operation);
      }
    } catch (error) {
      console.log(error.message);
    }
    // eslint-disable-next-line
  };

  //--------------------Update URL details for toggle button------------------------------------

  const updateUrlHandler = async (
    urlStatusUpdateOperation,
    urlName,
    urlDetails
  ) => {
    const operation = {
      operations_performed: sentenceCase(urlStatusUpdateOperation + "d"),
      component_type: "url",
      component_name: urlDetails.url_title + " in board - " + ctx.deviceName,
      update_by: activeAccount.name,
    };

    try {
      const authenticatedValue = await accessTokenForApi();
      const response = await fetch(BASE_URL + "/url/updateUrl", {
        method: "PUT",
        body: JSON.stringify(urlDetails),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authenticatedValue.accessToken,
        },
      });
      const data = await response.json();
      if (data.Status === "success") {
        openUrlStatusUpdateConfirmResetHandler();
        urlStatusUpdateHandler();
        addRecentActivity(operation);
      }
    } catch (error) {
      /* setAddUrlError(error.message || "Something went wrong!");
      console.log(addUrlError); */
    }
  };

  const toggleChecked = (e, status, row) => {
    SetSelectedRowUrlName(row.urltitle);
    SetOpenUrlStatusUpdateConfirm(true);
    const urlDetails = {
      board_id: ctx.deviceId,
      board_url_id: row.id,
      url_title: row.urltitle,
      readable_url_link: row.urllink,
      update_interval: row.updateinterval,
      updated_by: activeAccount ? activeAccount.name : "",
      active: status,
    };
    setUrlStatusUpdateOperation(status ? "enable" : "disable");
    setUrlDetailsState(urlDetails);
  };

  const onConfirmUrlStatusUpdateClick = (
    urlStatusUpdateOperation,
    urlName,
    urlDetails
  ) => {
    updateUrlHandler(urlStatusUpdateOperation, urlName, urlDetails);
  };

  useEffect(() => {
    fetchUrlList();
  }, [fetchUrlList]);

  return (
    <>
      <Card>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          sx={{ width: "100vw" }}
          open={openAddOrUpdateUrl}
          closeAfterTransition
          onClose={handleCloseUrlAddOrUpdate}
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={openAddOrUpdateUrl}>
            <Box sx={style}>
              <AddOrUpdateUrl
                isUpdate={isUpdateOperation}
                onCancelClick={handleCloseUrlAddOrUpdate}
                datas={dataInRow}
                onUpdateClick={urlAddOrUpdateStatusHandler}
              />
            </Box>
          </Fade>
        </Modal>
        {urlAddOrUpdateStatus && (
          <SuccessDialog
            open={urlAddOrUpdateStatus}
            component="Url"
            operation="added"
            onOkClick={urlAddOrUpdateStatusResetHandler}
          />
        )}
        {urlViewChangeStatus && (
          <SuccessDialog
            open={urlViewChangeStatus}
            component="Display Url"
            operation="switched"
            onOkClick={urlViewChangeStatusResetHandler}
          />
        )}
        {openDeleteUrlConfirm && (
          <AlertDialog
            open={openDeleteUrlConfirm}
            component="URL"
            name={selectedRowUrlName}
            operation="delete"
            onCancelClick={openDeleteUrlConfirmResetHandler}
            onOkClick={() => deleteUrl(selectedRowUrlId, selectedRowUrlName)}
          />
        )}

        {urlDeleteStatus && (
          <SuccessDialog
            open={urlDeleteStatus}
            component="URL"
            operation="deleted"
            onOkClick={urlDeleteStatusResetHandler}
          />
        )}

        {openUrlStatusUpdateConfirm && (
          <AlertDialog
            open={openUrlStatusUpdateConfirm}
            component="URL"
            operation={urlStatusUpdateOperation}
            isEnable={true}
            name={selectedRowUrlName}
            onCancelClick={openUrlStatusUpdateConfirmResetHandler}
            onOkClick={() =>
              onConfirmUrlStatusUpdateClick(
                urlStatusUpdateOperation,
                selectedRowUrlName,
                urlDetailsState
              )
            }
          />
        )}

        {urlStatusUpdate && (
          <SuccessDialog
            open={urlStatusUpdate}
            component="URL"
            operation={urlStatusUpdateOperation + "d"}
            onOkClick={urlStatusUpdateResetHandler}
          />
        )}

        <CardHeader title={props.title} />
        <Card>
          <DashboardsListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            searchComponent="url"
          />
          {isLoading ? (
            <Box sx={{ flexGrow: 1, textAlign: "center", paddingTop: 7 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <DashboardsListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={urlList.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUrls
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        const {
                          id,
                          urltitle,
                          urllink,
                          updateinterval,
                          updatedby,
                          status,
                          updatedat,
                        } = row;
                        const selectedUrl = selected.indexOf(id) !== -1;

                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="button"
                            selected={selectedUrl}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              padding="none"
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                                sx={{
                                  width: "10vw",
                                  cursor: "pointer",
                                  paddingLeft: 2,
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  noWrap
                                  onClick={() => handleRowClick(row)}
                                >
                                  <strong>{urltitle}</strong>
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{
                                maxWidth: "20vw",
                                tableLayout: "fixed",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                cursor: "pointer",
                              }}
                              onClick={() => handleRowClick(row)}
                            >
                              {urllink}
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{
                                cursor: "pointer",
                              }}
                              onClick={() => handleRowClick(row)}
                            >
                              {updateinterval}
                            </TableCell>
                            <TableCell align="left">{updatedby}</TableCell>
                            <TableCell align="left">
                              {convertToCET(updatedat)}
                            </TableCell>
                            <TableCell align="left">
                              <Stack direction="row" spacing={2}>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <StatusSwitch
                                        sx={{ m: 2 }}
                                        checked={status}
                                        onChange={(e) =>
                                          toggleChecked(e, !status, row)
                                        }
                                      />
                                    }
                                    label={`${status ? "Active" : "Inactive"}`}
                                  />
                                </FormGroup>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">
                              {status ? (
                                <Tooltip
                                  title="Show this Dashboard on screen"
                                  arrow
                                >
                                  <StyledButton
                                    size="small"
                                    alt="Switch display to this URL"
                                    onClick={() => handleChangeViewUrl(row.id)}
                                  >
                                    <EyeIconWrapper>
                                      <Iconify
                                        icon="eva:eye-fill"
                                        style={{ color: "#808080" }}
                                      />
                                      {/* Change icon color to grey */}
                                    </EyeIconWrapper>
                                    Display this URL
                                  </StyledButton>
                                </Tooltip>
                              ) : (
                                ""
                              )}
                            </TableCell>
                            <TableCell align="right">
                              <IconButton
                                size="large"
                                color="inherit"
                                onClick={(e) =>
                                  handleOpenMenu(e, id, urltitle, row)
                                }
                              >
                                <Iconify icon={"eva:more-vertical-fill"} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Try checking for typos or using complete
                              words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
          )}

          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={urlList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

        <Divider />
        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              "& .MuiMenuItem-root": {
                px: 1,
                typography: "body2",
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem onClick={handleEditClick}>
            <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
            Edit
          </MenuItem>

          <MenuItem sx={{ color: "error.main" }} onClick={handleDeleteUrlClick}>
            <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
            Delete
          </MenuItem>
        </Popover>
      </Card>
    </>
  );
}
