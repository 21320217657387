import React from "react";
import { Navigate } from "react-router-dom";
// layouts
import HomePage from "./components/pages/homepage/HomePage";
import UserLoggedInLandingPage from "./components/pages/loggedInHomepage/UserLoggedInLandingPage";
import TeamsOverviewPage from "./components/pages/teamsPage/TeamsOverviewPage";
import DashboardsOverviewPage from "./components/pages/dashboardpage/DashboardsOverviewPage";
import OfficePage from "./components/pages/offices/OfficesPage";
import DashboardLayout from "./layouts/dashboard/DashboardLayout";
import DeviceOverviewPage from "./components/pages/deviceOverviewPage/DeviceOverviewPage";
import OfficeOverviewPage from "./components/pages/offices/OfficeOverviewPage";
import NotFoundPage from "./components/pages/notFoundPage/NotFoundPage"; // Optional 404 page
import DeviceOverviewPageV2 from "./components/pages/deviceOverviewPageV2/DeviceOverviewPage-v2";

// Helper function to check if the token is valid
const isTokenValid = () => {
  const token = localStorage.getItem("token"); // Replace 'token' with your token key
  // Add token validation logic here (e.g., expiration check)
  return !!token; // Simplified; returns true if a token exists
};

const routes = [
  {
    path: "/",
    element: isTokenValid() ? (
      <Navigate to="/dashboard/home" replace />
    ) : (
      <HomePage />
    ),
  },
  {
    path: "/dashboard",
    element: <DashboardLayout />,
    children: [
      {
        path: "home",
        element: <UserLoggedInLandingPage />,
        index: true,
      },
      {
        path: "teams",
        element: <TeamsOverviewPage />,
      },
      {
        path: "offices",
        element: <OfficeOverviewPage />,
      },
      {
        path: "officedetails",
        element: <OfficePage />,
      },
      {
        path: "pi_devices",
        element: <DashboardsOverviewPage />,
      },
      {
        path: "deviceoverview",
        element: <DeviceOverviewPage />,
      },
      {
        path: "device/:id",
        element: <DeviceOverviewPageV2 />,
      },
    ],
  },
  // Wildcard route for unmatched paths
  {
    path: "*",
    element: <NotFoundPage />,
  },
];

export default routes;
