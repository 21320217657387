import React from "react";
import { useNavigate } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";

import MUIHeader from "../../UI/Header/MUIHeader";


const ErrorPage = () => {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated(); // Check if the user is authenticated

  const handleGoBack = () => {
    // You can also modify the handleGoBack to navigate based on auth status if needed
    if (isAuthenticated) {
      navigate("/dashboard/home"); // Navigate to the dashboard for authenticated users
    } else {
      navigate("/"); // Navigate to the home page for unauthenticated users
    }
  };
  return (
    <div style={styles.container}>
              <MUIHeader />

      <main style={styles.main}>
        <h1 style={styles.title}>Oops! Page Not Found</h1>
        <p style={styles.subtitle}>
          The page you are looking for doesn't exist or has been moved.
        </p>
        <button style={styles.homeButton} onClick={handleGoBack}>
          Go Back to Home
        </button>
      </main>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    background: "linear-gradient(to bottom, #ffffff, #e0f7fa)",
  },
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem 2rem",
    backgroundColor: "#1e293b",
    color: "#ffffff",
  },
  logo: {
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  backButton: {
    backgroundColor: "transparent",
    color: "#ffffff",
    border: "none",
    cursor: "pointer",
    fontSize: "1rem",
  },
  main: {
    textAlign: "center",
    padding: "2rem",
  },
  title: {
    fontSize: "2.5rem",
    color: "#1e88e5",
    marginBottom: "1rem",
  },
  subtitle: {
    fontSize: "1.2rem",
    color: "#37474f",
    marginBottom: "2rem",
  },
  homeButton: {
    backgroundColor: "#1e88e5",
    color: "#ffffff",
    padding: "0.75rem 1.5rem",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "1rem",
  },
  illustrationContainer: {
    marginTop: "2rem",
  },
  illustration: {
    maxWidth: "400px",
    width: "100%",
  },
};

export default ErrorPage;
